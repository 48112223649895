export default function NavBar() {
  return (
    <div className="p-4 md:flex md:items-center md:justify-between">
      <a
        href="/"
        className="w-full text-white no-underline font-bold text-2xl md:text-[36px] flex items-center justify-center md:justify-start"
      >
        <span className="text-blue-800 no-underline font-bold text-2xl text-[48px] md:text-[58px]">
          O
        </span>
        &nbsp;Orlando Car Detailing
      </a>
    </div>
  );
}
