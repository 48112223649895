import React, { useState } from "react";
import FaqModalRender from "./FaqsModal";

export default function Footer() {
  const handleClick = () => {
    alert("We are not currently accepting new detailers.");
  };

  const [modalShow, setModalShow] = useState(false);

  const handleFaqClick = () => {
    alert("We are not currently accepting new detailers.");
  };


  return (
    <div className="flex flex-col items-center">
      <FaqModalRender modalShow={modalShow} setModalShow={setModalShow} />

      <div>
        <a
          className="bg-white text-black no-underline border border-black rounded-2xl px-4 m-1 font-bold cursor-pointer"
          onClick={() => setModalShow(true)}
        >
          FAQs
        </a>
      </div>
      <button
        className="bg-white border border-black rounded-2xl px-4 m-3 font-bold"
        onClick={handleFaqClick}
      >
        Join our Pro Network
      </button>
      <div className="text-center">
        <span>&copy;</span> 2024 orlandocardetailing.com
      </div>
    </div>
  );
}
